import * as React from 'react';
import s from "./Slideshow.module.scss";
import { useEffect, useState } from 'react';
import {c} from "../utils";


export interface SlideshowProps extends React.HTMLAttributes<HTMLDivElement> {
  images: string[];
}

export const Slideshow = ({ images, className, ...props }: SlideshowProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={c(s.slideshow, className)}
         {...props}>
      {images.map((image, index) => (
        <div
          key={index}
          className={c(s.slide, index === currentIndex && s.active)}
        >
          <img src={image} alt={`Slide ${index}`} />
        </div>
      ))}
    </div>
  );
};
