/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import '../index.scss';
import {Header} from "../components/Header";
import {Dot, Icon} from "../components/Logo";
import {Plans} from "../components/Plans";
import askVideo from "../assets/videos/BLUF Ask.mp4";
import summarizeVideo from "../assets/videos/BLUF Summarize.mp4";
import explainVideo from "../assets/videos/BLUF Explain.mp4";
import youtubeVideo from "../assets/videos/BLUF Youtube.mp4";
import {Page} from "../components/Page";
import {mdiAccountSchool, mdiAccountSchoolOutline, mdiAccountTie, mdiClockFast, mdiConnection, mdiFilePdfBox,
  mdiFlask,
  mdiForum, mdiHeadLightbulb,
  mdiHeadSnowflakeOutline, mdiKeyboard,
  mdiShieldSwordOutline,
  mdiTypewriter, mdiWeb, mdiYoutube } from '@mdi/js';
import chrome from '../assets/images/browsers/chrome.png'
import edge from '../assets/images/browsers/edge.png'
import firefox from '../assets/images/browsers/firefox.png'
import opera from '../assets/images/browsers/opera.png'
import mockshot1 from '../assets/images/mockshots/The Bluf Times.png'
import mockshot2 from '../assets/images/mockshots/YouTube Lecture.png'
import mockshot3 from '../assets/images/mockshots/ACME Financial.png'
import {Slideshow} from "../components/Slideshow";
import {AddToBrowser} from "../components/AddToBrowser";
import {Faq} from "../components/Faq";
import {useExperiment} from "../utils/experiments";
import { navigate } from 'gatsby';

const IndexPage = () => {
  const signUpCta = useExperiment('signup-cta');

  return <Page
    title='Summarize any PDF, Website or YouTube Video'
    name='home'
    className='branding'
  >
    <Header/>
    <section className="headline">
      <div className='title left-border'>
        <span className='tag-line'>
          Get the <b>Bottom Line</b> <b>Up Front<Dot/></b>
        </span>
        <h1>
          <b>Summarize</b> and <b>Chat</b> with any PDF, Website or YouTube Video
        </h1>
        {signUpCta === 'signup' ?
          <button style={{ width: '100%' }} onClick={() => navigate('/signup?intent=choose_plan')}>Get Started</button> :
          <AddToBrowser>Get Started</AddToBrowser>
        }
      </div>

      <Slideshow images={[mockshot1, mockshot2, mockshot3]} className='slideshow'/>

    </section>

    <section className='light'>
      <div className='center'>
        <h3 className='alt-heading'>What is BLUF?</h3>
        <h2>Your AI-Powered <u>Reading Partner</u><Dot/></h2>
        <p>
          BLUF stands for (Bottom Line Up Front).
          It is an AI-powered browser extension that quickly summarizes and explains content
          from any website, PDF document, or YouTube video, ensuring you get the most important
          information without the fluff.
        </p>
      </div>
    </section>

    <section>
      <div className='center'>
        <h3 className='alt-heading'>Why Use BLUF?</h3>
        <h2><u>Browse Efficiently</u> and Boost Your Understanding<Dot/></h2>
      </div>
      <div className='columns why-bluf'>
        <div className='item'>
          <Icon path={mdiClockFast}/>
          <h3>Summarize Anything<Dot/></h3>
          <p>
            Quickly get the main points of any content, saving valuable time.
          </p>
        </div>
        <div className='item'>
          <Icon path={mdiHeadSnowflakeOutline}/>
          <h3>Expand Your Knowledge<Dot/></h3>
          <p>
            Turn complex concepts into easy-to-read explanations
          </p>
        </div>
        <div className='item'>
          <Icon path={mdiConnection}/>
          <h3>Fully Integrated<Dot/></h3>
          <p>
            Works on Websites, PDFs, YouTube videos, and local files.
          </p>
        </div>
        <div className='item'>
          <Icon path={mdiKeyboard}/>
          <h3>Quick Access<Dot/></h3>
          <p>
            Use the keyboard shortcut <b className='no-wrap'>Shift + Alt + B</b> for instant access.
          </p>
        </div>
      </div>
    </section>

    <hr/>

    <section className='features' id='features'>
      <div>
        <h2 className='big'>Key Features<Dot/></h2>
      </div>
      <div className='feature'>
        <div className='left-border'>
          <h2>Summarize<Dot/></h2>
          <p>Quickly extract the key highlights from any content in seconds. Follow up with questions to delve deeper into specific points and get the concise information you need.</p>
        </div>
        <video autoPlay loop muted playsInline src={summarizeVideo}/>
      </div>
      <div className='feature flipped'>
        <div className='left-border'>
          <h2>Explain<Dot/></h2>
          <p>Turn complex concepts into clear, understandable information. Break down intricate documents and technical jargon into simple terms that are easy to digest.</p>
        </div>
        <video autoPlay loop muted playsInline src={explainVideo}/>
      </div>
      <div className='feature'>
        <div className='left-border'>
          <h2>Ask<Dot/></h2>
          <p>Refine your search to get only the information you need. Let the AI handle the heavy lifting, providing you with precise answers and saving you valuable time.</p>
        </div>
        <video autoPlay loop muted playsInline src={askVideo}/>
      </div>
      <div className='feature flipped'>
        <div className='left-border'>
          <h2>Wide Compatability<Dot/></h2>
          <p>Integrates with websites, PDFs, YouTube videos, and local files. Enjoy seamless functionality across various content types and formats, making it easier than ever to absorb information from any source.</p>
        </div>
        <video autoPlay loop muted playsInline src={youtubeVideo}/>
      </div>
    </section>

    <hr/>
    <section>
      <div className='center'>
        <h3 className='alt-heading'>Summarize vs Explain</h3>
        <h2>Digests That <u>Fit Your Use Case</u><Dot/></h2>
      </div>
      <div className='columns'>
        <div className='item'>
          <Icon path={mdiClockFast}/>
          <h3 className='self-center'>Summarize<Dot/></h3>
          <p>
            Reading a long news article, or email? Use the <b>summarize</b> option to get the key points.
          </p>
        </div>
        <div className='item'>
          <Icon path={mdiHeadSnowflakeOutline}/>
          <h3 className='self-center'>Explain<Dot/></h3>
          <p>
            Learning something new?<br/>The <b>explain</b> option will generate a simple to understand explanation.
          </p>
        </div>
      </div>
    </section>

    <hr/>

    <section>
      <div className='center'>
        <h3 className='alt-heading'>How to Use BLUF</h3>
        <h2>Quick Insights, <u>Right from Your Browser</u><Dot/></h2>
      </div>
      <ol className='left-border steps'>
        <li>
          <h3>Navigate to the Content<Dot/></h3>
          <p>Locate the website, PDF, or YouTube video you need summarized.</p>
        </li>
        <li>
          <h3>Activate the Extension<Dot/></h3>
          <p>
            Right-click on text, click the toolbar icon, or press <b className='no-wrap'>Shift + Alt + B</b>.
          </p>
        </li>
        <li>
          <h3>Get the Bottom Line<Dot/></h3>
          <p>Click <b>summarize</b> or <b>explain</b> to get the bottom line.</p>
        </li>
        <li>
          <h3>Ask a Follow-Up<Dot/></h3>
          <p>Dive deeper by asking follow-up questions about the content.</p>
        </li>
      </ol>
    </section>

    <section className='light'>
      <div className='center'>
        <h3 className='alt-heading'>Supported Browsers</h3>
        <h2>Compatible with <u>Your Favorite Browser</u><Dot/></h2>
      </div>
      <div className='columns browsers'>
        <div className='item'>
          <img src={chrome}/>
          <h3>Chrome</h3>
        </div>
        <div className='item'>
          <img src={firefox}/>
          <h3>Firefox</h3>
        </div>
        <div className='item'>
          <img src={edge}/>
          <h3>Edge</h3>
        </div>
        <div className='item'>
          <img src={opera}/>
          <h3>Opera</h3>
        </div>
      </div>
    </section>

    <section>
      <div className='center'>
        <h3 className='alt-heading'>Who Can Benefit from BLUF?</h3>
        <h2>Tailored for <u>Any Use Case</u><Dot/></h2>
      </div>
      <div className='columns use-cases'>
        <div className='item'>
          <Icon path={mdiShieldSwordOutline} />
          <h3>Forum Moderators<Dot/></h3>
          <p>
            Identify key points to foster meaningful discussions and enhance community engagement.
          </p>
        </div>
        <div className='item'>
          <Icon path={mdiFlask} />
          <h3>Researchers<Dot/></h3>
          <p>
            Quickly grasp essential concepts from complex academic papers for thorough analysis.
          </p>
        </div>
        <div className='item'>
          <Icon path={mdiAccountSchool} />
          <h3>Students<Dot/></h3>
          <p>
            Grasp key concepts from complex academic papers quickly.
          </p>
        </div>
        <div className='item'>
          <Icon path={mdiTypewriter} />
          <h3>Journalists<Dot/></h3>
          <p>
            Understand key concepts from intricate academic texts to improve learning and study efficiency.
          </p>
        </div>
        <div className='item'>
          <Icon path={mdiForum} />
          <h3>Social Media Managers<Dot/></h3>
          <p>
            Extract key ideas to craft engaging posts and streamline content promotion.
          </p>
        </div>
        <div className='item'>
          <Icon path={mdiAccountTie} />
          <h3>Busy Professionals<Dot/></h3>
          <p>
            Summarize emails, reports, or news articles to stay informed and manage time efficiently.
          </p>
        </div>
      </div>
    </section>

    <hr/>

    <section id='pricing'>
      <h2 className='big'>Pricing<Dot/></h2>
      <Plans/>
    </section>

    <section className='light'>
      <div className='center'>
        <h3 className='alt-heading'>Experience the Power of BLUF</h3>
        <h2>Start <u>Saving Time</u> Now!</h2>
        <p>Summarize and explain any content effortlessly with BLUF,
          your AI-powered reading partner. Save time, enhance understanding,
          and browse smarter with the Bottom Line Up Front.
        </p>
        {signUpCta === 'signup' ?
          <button style={{ maxWidth: 200, margin: '24px auto 24px auto', minWidth: 200, boxSizing: 'content-box' }}
                  onClick={() => navigate('/signup?intent=choose_plan')}>Get Started</button> :
          <AddToBrowser style={{ maxWidth: 200, margin: '24px auto 24px auto' }}>Get Started</AddToBrowser>
        }
      </div>
    </section>

    <section>
      <Faq/>
    </section>
  </Page>;
};
export default IndexPage;
