import * as React from 'react';
import {CHROME_WEBSTORE_LINK, FIREFOX_ADDONS_LINK} from "../utils/extension";
import firefoxSvg from "../assets/firefox.svg";
import chromeSvg from "../assets/chrome.svg";
import {isFirefox} from "../utils/browser";
import {track} from "../utils/analytics";
import {c} from "../utils";


export interface AddToBrowserProps extends React.HTMLAttributes<HTMLButtonElement> {
}

export const AddToBrowser = ({className, children, ...props}: AddToBrowserProps) => {
  const [browser, setBrowser] = React.useState<string>();
  React.useLayoutEffect(() => {
    if (isFirefox()) {
      setBrowser('Firefox');
    } else {
      setBrowser('Chrome');
    }
  }, []);

  return <a
    className={c('button', className)}
    /* @ts-ignore */
    onClick={() => track('clicked_add_to_browser', { browser })}
    href={browser === 'Firefox' ? FIREFOX_ADDONS_LINK : CHROME_WEBSTORE_LINK}
    target='_blank'
    {...props}>
    {!!children ? children : <>
      <svg className="chrome">
        <image href={browser === 'Firefox' ? firefoxSvg : chromeSvg}/>
      </svg>
      <span>Add To {browser}</span>
    </>}
  </a>
}
